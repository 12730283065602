<template>
  <div>
    <span class="viewH">Курсы</span>
    <hr>
    <div class="courses" v-if="visible == 'course'">
      <div class="courses-item" @click="editCourse(_course.truck.id)">{{_course.truck.title}}</div>
      <div class="courses-item" @click="editCourse(_course.truckD.id)">{{_course.truckD.title}}</div>
      <div class="courses-item" @click="editCourse(_course.truckR.id)">{{_course.truckR.title}}</div>
    </div>
    
    <div class="table" v-if="visible == 'truck'">
      <table class="table-item">
        <tr>
          <td>Требования:</td>
          <td><input type="text" v-model="_course.truck.treb"></td>
        </tr>
        <tr>
          <td>Документы:</td>
          <td><input type="text" v-model="_course.truck.doc"></td>
        </tr>
        <tr>
          <td>Срок обучения:</td>
          <td><input type="text" v-model="_course.truck.term"></td>
        </tr>
        <tr>
          <td>Стажировака проходит на автомобилях:</td>
          <td><input type="text" v-model="_course.truck.car"></td>
        </tr>
        <tr>
          <td>Выдаваемые документы:</td>
          <td><input type="text" v-model="_course.truck.doc_"></td>
        </tr>
        <tr>
          <td>Стоимость:</td>
          <td><input type="text" v-model="_course.truck.cost"></td>
        </tr>
      </table>
      <div class="table-btn">
        <v-btn small color="red" @click="cancel">Отмена</v-btn>
        <v-btn small color="green" @click="save">Сохранить</v-btn>
      </div>
    </div>

    <div class="table" v-if="visible == 'truckD'">
      <table class="table-item">
        <tr>
          <td>Стажировака проходит на автомобилях:</td>
          <td><input type="text" v-model="_course.truckD.car"></td>
        </tr>
        <tr>
          <td>Срок обучения:</td>
          <td><input type="text" v-model="_course.truckD.term"></td>
        </tr>
        <tr>
          <td>Стоимость:</td>
          <td><input type="text" v-model="_course.truckD.cost"></td>
        </tr>
      </table>
      <div class="table-btn">
        <v-btn small color="red" @click="cancel">Отмена</v-btn>
        <v-btn small color="green" @click="save">Сохранить</v-btn>
      </div>
    </div>

    <div class="table" v-if="visible == 'truckR'">
      <div class="gr">
        <div>
          <table class="table-item">
            <tr>
              <td>Занятие на тягаче:</td>
              <td>автодром</td>
            </tr>
            <tr>
              <td>Автомобили:</td>
              <td><input type="text" v-model="_course.truckR.autodrome.car"></td>
            </tr>
            <tr>
              <td>Стоимость одного занятия:</td>
              <td><input type="text" v-model="_course.truckR.autodrome.cost"></td>
            </tr>
          </table>
        </div>
        <div>
          <table class="table-item">
            <tr>
              <td>Занятие на тягаче:</td>
              <td>городской цикл</td>
            </tr>
            <tr>
              <td>Автомобили:</td>
              <td><input type="text" v-model="_course.truckR.city.car"></td>
            </tr>
            <tr>
              <td>Стоимость одного занятия:</td>
              <td><input type="text" v-model="_course.truckR.city.cost"></td>
            </tr>
          </table>
        </div>
      </div>
      <div class="table-btn">
        <v-btn small color="red" @click="cancel">Отмена</v-btn>
        <v-btn small color="green" @click="save">Сохранить</v-btn>
      </div>
    </div>
    
  </div>
</template>
<script>
import {mapGetters, mapActions} from 'vuex'
export default {
  props: {
    messageOn: Function,
    loadingWindow: Function
  },
  data: () => ({
    courses: {},
    visible: 'course'
  }),
  computed: {
    _course() {
      this.courses = this.getCourses()
      return this.courses
    }
  },
  methods: {
    ...mapGetters(['getCourses']),
    ...mapActions(['saveCourses']),
    editCourse(id) {
      this.visible = id
    },
    async save () {
      this.loadingWindow(true)
      let res = await this.saveCourses(this.courses)
      this.loadingWindow(false)
      if(res.saveCourses && res.saveCourses == 'ok') {
        this.messageOn('Изменения сохранены', 'green')
      } else {
        this.messageOn('[ошибка:] Изменения не сохранены', 'red')
      }
      this.visible = 'course'
    },
    async cancel() {
      await this.$store.dispatch('courses')
      this.visible = 'course'
    }
  }
}
</script>

<style lang="scss" scoped>
  .courses {
    margin-top: 25px;
    .courses-item {
      margin: 10px 0 0 0;
      padding: 5px;
      max-width: 500px;
      background-color: #696969;
      cursor: pointer;
      border-radius: 3px;
      transition: all .3s;
      &:hover {
        background-color: #4caf50;
      }
    }
  }
  .table {
    margin-top: 25px;
    .table-item {
      width: 100%;
      td {
        border: 1px solid #d3d3d3;
        border-radius: 3px;
        padding: 3px;
        input {
          color: #ffffff;
          width: 100%;
        }
      }
    }
  }
  .gr {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    gap: 10px;
  }
  .table-btn {
    margin-top: 15px;
    button {
      &:nth-child(2) {
        margin: 0 7px;
      }
    }
  }
</style>
